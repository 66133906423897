@import url(https://fonts.googleapis.com/css?family=Mr+Dafoe);
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@600&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300&display=swap');

@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300&display=swap');

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #0a192f;
  color: #f5f5f5;
  font-family: 'Poppins', 'Open sans', sans-serif;
  font-size: 16px;

  margin: 0;
  padding: 0;
}

textarea,
input {
  color: #f5f5f5;
  background-color: #0a192f;
  font-family: 'Poppins', 'Open sans', sans-serif;
  font-size: 14px;
}

h1 {
  font-family: 'Poppins', 'Open sans', sans-serif;
  font-size: 40px;
}

h2 {
  font-family: 'Poppins', 'Open sans', sans-serif;
}

button {
  font-family: 'Poppins', 'Open sans', sans-serif;
  color: #f5f5f5;
  font-weight: 600;
  font-size: 16px;
}

.carousel .slide {
  display: flex;
  justify-content: center;
}

.toastify-container {
  padding: 0 !important;
  background: none !important;
  box-shadow: none !important;
}

.toastify-body {
  padding: 0 !important;
  margin: 0 !important;
}
